import React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import ClipLoader from 'react-spinners/ClipLoader';
import { useDispatch, useSelector } from 'react-redux';
import { payReferral } from '../../store/slices/referralSlice';

const UpdateReferralForm = ({ selectedReferralData, onClose }) => {
    const dispatch = useDispatch();

    const initialValues = {
        userID: selectedReferralData || '', 
        numberOfRefs: 1, 
        override: false, 
    };
    

    const validationSchema = Yup.object().shape({
        userID: Yup.string().required('User ID is required'),
        numberOfRefs: Yup.number().required('Number of Referrals is required').min(1, 'Must be at least 1'),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        const payload = {
            userID: values.userID,
            numberOfRefs: values.numberOfRefs,
            override: values.override,
        };
    
        try {
            const resultAction = await dispatch(payReferral(payload));
            if (payReferral.fulfilled.match(resultAction)) { 
                onClose(); 
            }
        } catch (error) {
            console.error("Payment failed:", error);
        } finally {
            setSubmitting(false);
        }
    };
    

    const referralState = useSelector((state) => state.referral);
    const loading = referralState.isLoading;
    console.log("loading state is:", loading)

    return (
        <div className="w-full">
            <h4 className='font-extrabold text-xl text-center'>Update Referral</h4>
            <Formik 
                initialValues={initialValues} 
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                enableReinitialize
            >
                {({ isSubmitting, setFieldValue, values }) => (
                    <Form className="w-full flex flex-col gap-4 mt-6">
                        <div className="flex flex-col w-full gap-2">
                            <label className="text-[14px] font-semibold text-custom-secondary">User ID</label>
                            <Field
                                type="text"
                                id="userID"
                                name="userID"
                                placeholder="Enter User ID"
                                className="pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full placeholder:text-[13px] bg-comp-7 border-2 sm:border-none text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light"
                            />
                            <ErrorMessage name="userID" component="div" className="text-red-500 text-sm" />
                        </div>

                        <div className="flex flex-col w-full gap-2">
                            <label className="text-[14px] font-semibold text-custom-secondary">Number of Referrals</label>
                            <Field
                                type="number"
                                id="numberOfRefs"
                                name="numberOfRefs"
                                placeholder="Enter Number of Referrals"
                                className="pl-2 lg:px-[16px] py-[10px] rounded-[2.5px] w-full placeholder:text-[13px] bg-comp-7 border-2 sm:border-none text-[15px] outline-none font-semibold text-grey-7 placeholder:text-grey-6 placeholder:font-light"
                            />
                            <ErrorMessage name="numberOfRefs" component="div" className="text-red-500 text-sm" />
                        </div>

                        <div className="flex flex-col w-full gap-2">
                            <label className="text-[14px] font-semibold text-custom-secondary">Override</label>
                            <Field
                                type="checkbox"
                                id="override"
                                name="override"
                                className="w-5 h-5"
                            />
                            <ErrorMessage name="override" component="div" className="text-red-500 text-sm" />
                        </div>

                        <button
                            type="submit"
                            className="bg-custom-primary w-full hover:bg-black text-white hover:text-white shadow-sm py-3 rounded-[2.5px] whitespace-nowrap text-[14px] lg:text-[15px] font-[600]"
                            disabled={loading}
                        >
                            {loading ? (
                                <ClipLoader
                                    color="custom-primary"
                                    loading="loading"
                                    css="override"
                                    size={20}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            ) : (
                                'Update Referral'
                            )}
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default UpdateReferralForm;
